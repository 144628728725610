
import { ref, reactive, defineComponent, computed } from 'vue';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import FlexInput from '@/components/UI/Input.vue';

import { MESSAGES } from '@/constants/errors';
import { FormHandler } from '@/classes/forms/FormHandler';
import { FormStructure, FormFields } from '@/interfaces/Input';
import { Donor } from '@/classes/models/Donor';
import { DonorApi } from '@/classes/api/DonorApi';

import DonorFormLayout from './DonorFormLayout.vue';
import DonorFormFields from '@/constants/FormFields/donor';

import { useToast } from 'primevue/usetoast';
import CustomError from '@/classes/models/CustomError';

export default defineComponent({
  emits: ['onSubmit', 'update:visible'],
  props: {
    visible: Boolean,
    id: Number,
    editMode: Boolean,
  },
  components: {
    DonorFormLayout, Dialog, Button, FlexInput
  },
  setup(props, ctx) {
    const item = reactive<Donor>(new Donor({}));
    const loading = ref(false);
    const fields = reactive<FormFields>({ ...DonorFormFields });

    const formHandler = new FormHandler(fields, item)
    const formStructure: FormStructure = formHandler.createFormStructure();
    const toast = useToast();
    const api = new DonorApi();

    const inputVisible = computed({
      get: () => props.visible,
      set: val => {
        ctx.emit('update:visible', val);
      },
    });

    const setItem = (itm: Donor) => {
      item.replaceBy(itm);
      formHandler.setObjectToValidate(item);
    };

    const resetValidations = () => {
      Object.values(fields).forEach(field => {
        field.invalid = false;
        field.validationErrors = [];
      });
    };

    const resetFormHandler = () => {
      setItem(new Donor({}));
      resetValidations();
    };

    const updateVisibleHandler = (value: boolean) => {
      ctx.emit('update:visible', value);
    };

    const fetch = async () => {
      if (props.editMode && props.id) {
        loading.value = true;
        try {
          item.replaceBy(await api.fetchById(props.id));
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        loading.value = false;
      } else {
        resetFormHandler();
      }
    }

    const submitHandler = async () => {
      const valid = await formHandler.checkValidations();
      if (valid) {
        loading.value = true;
        try {
          if (!props.editMode) {
            await api.send(item);
          } else {
            await api.update(item);
          }
          ctx.emit('onSubmit', item);
          resetFormHandler();
          updateVisibleHandler(false);
          inputVisible.value = false;
        } catch (error) {
          if (error instanceof CustomError) {
            error.show('error', 5000, toast);
          } else {
            console.log(error);
          }
        }
        inputVisible.value = false;
      } else {
        toast.add({
          severity: 'warn',
          detail: MESSAGES.generalFormErrors,
          life: 3000,
        });
      }
    }

    return {
      formStructure,
      fields,
      item,
      formHandler,
      loading,
      fetch,
      submitHandler,
      resetFormHandler,
      updateVisibleHandler,
    }
  }
});

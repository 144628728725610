import { FormFields } from '@/interfaces/Input';
import { DRIVE, KPP, STATUS, WHEEL } from '@/classes/models/Donor';

const fields: FormFields = {
  number: {
    title: 'Номер',
    type: 'input',
    slot: 'number',
  },
  name: {
    title: 'Наименование',
    type: 'input',
    slot: 'name',
  },
  lotId: {
    title: 'Поставка',
    type: 'lot',
  },
  status: {
    title: 'Статус',
    type: 'select',
    options: [
      { title: 'В городе приобретения', value: STATUS.CITY_OF_ORIGIN },
      { title: 'В пути', value: STATUS.IN_TRANSIT },
      { title: 'В городе назначения', value: STATUS.CITY_OF_DESTINATION },
    ],
  },
  marka: {
    title: 'Марка',
    type: 'input',
    slot: 'middleLeft',
  },
  model: {
    name: 'model',
    title: 'Модель',
    type: 'input',
    slot: 'middleRight',
  },
  bodyNumber: {
    title: 'Кузов',
    type: 'input',
    slot: 'bottom',
  },
  engine: {
    title: 'Двигатель',
    type: 'input',
    slot: 'bottom',
  },
  year: {
    title: 'Год',
    type: 'input',
    slot: 'bottom',
    variableType: 'number',
  },
  wheel: {
    title: 'Руль',
    type: 'select',
    options: [
      { title: 'Левый', value: WHEEL.LEFT },
      { title: 'Правый', value: WHEEL.RIGHT },
    ],
    slot: 'bottom',
  },
  kpp: {
    title: 'Тип КПП',
    type: 'select',
    options: [
      { title: 'Автоматическая', value: KPP.AUTOMATIC },
      { title: 'Механическая', value: KPP.MANUAL },
      { title: 'Роботизированная', value: KPP.ROBOT },
      { title: 'Вариаторная', value: KPP.VARIATOR },
    ],
    slot: 'bottom',
  },
  kppModel: {
    title: 'Модель КПП',
    type: 'input',
    slot: 'bottom',
  },
  drive: {
    title: 'Тип привода',
    type: 'select',
    options: [
      { title: 'Передний', value: DRIVE.FRONT },
      { title: 'Полный', value: DRIVE.FULL },
      { title: 'Задний', value: DRIVE.REAR },
    ],
    slot: 'bottom',
  },
  complectation: {
    title: 'Комплектация',
    type: 'input',
    slot: 'bottom',
  },
  vin: {
    title: 'VIN',
    type: 'input',
    slot: 'bottom',
  },
  description: {
    title: 'Описание',
    type: 'textarea',
    slot: 'bottom',
  },
  note: {
    title: 'Заметка',
    type: 'textarea',
    slot: 'bottom',
  },
  mileage: {
    title: 'Пробег',
    type: 'input',
    slot: 'bottom',
  },
  color: {
    title: 'Цвет',
    type: 'input',
    slot: 'bottom',
  },
  price: {
    title: 'Цена',
    type: 'input',
    slot: 'bottom',
    variableType: 'number',
  },
  images: {
    title: 'Фотографии',
    type: 'photos',
    slot: 'bottom',
  },
};

export default fields;

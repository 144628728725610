<template>
  <div class="p-formgrid p-grid">
    <div class="p-col-3">
      <slot name="number"></slot>
    </div>
    <div class="p-col-9">
      <slot name="name"></slot>
    </div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-col">
      <slot></slot>
    </div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-col"><slot name="middleLeft"></slot></div>
    <div class="p-col"><slot name="middleRight"></slot></div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-col">
      <slot name="bottom"></slot>
    </div>
  </div>
  <div class="p-d-flex">
    <slot name="actions"></slot>
  </div>
</template>

<script>
export default {}
</script>

<style></style>


import {defineComponent, ref, reactive, onMounted} from 'vue';

import {useConfirm} from 'primevue/useconfirm';
import {useToast} from 'primevue/usetoast';
import DataTable from '@/components/UI/DataTable/DataTable.vue';
import Column from 'primevue/column';
import Button from 'primevue/button';
import ContextMenu from 'primevue/contextmenu';

import {Donor} from '@/classes/models/Donor';
import {DonorApi} from '@/classes/api/DonorApi';
import CustomError from '@/classes/models/CustomError';

import DonorForm from './DonorForm.vue';

export default defineComponent({
  components: {
    'donor-form': DonorForm,
    DataTable, Column, Button, ContextMenu
  },

  setup() {
    const item = reactive<Donor>(new Donor({}));
    const selectedProduct = ref<Donor>(new Donor({}));
    const selectedProducts = ref<Donor[]>([]);
    const productDialog = ref(false);
    const products = ref<Donor[]>([]);
    const loading = ref(false);
    const editMode = ref(false);
    const pages = ref(0);
    const loadedRecords = ref(0);
    const page = ref(0);
    const size = ref(40);
    const totalRecords = ref(0);
    // eslint-disable-next-line
    const cm = ref<any>(null);

    const headerStyle = 'width: 15rem';
    const confirm = useConfirm();
    const toast = useToast();
    const api = new DonorApi();

    const getImageUrl = (name: string): string => {
      return `${process.env.VUE_APP_IMAGE_URL}${name}`;
    }

    const update = async () => {
      page.value = 0;
      loading.value = true;
      try {
        const result = await api.fetchAll(page.value + 1, size.value);
        products.value = result.data || [];
        totalRecords.value = result.rows || 0;
        loadedRecords.value = result.data ? result.data.length : 0;
        pages.value = result.pages || 0;
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    }

    onMounted(() => {
      update();
    });

    // eslint-disable-next-line
    const updatePage = (event: any) => {
      page.value = event.page;
      update();
    }

    // eslint-disable-next-line
    const onRowContextMenu = (event: any) => {
      cm.value.show(event.originalEvent);
    }

    const openNew = () => {
      editMode.value = false;
      productDialog.value = true;
    }

    const editProduct = (selected: Donor) => {
      item.replaceBy(selected);
      editMode.value = true;
      productDialog.value = true;
    }

    const confirmDeleteSelected = (items: Donor[]) => {
      console.log('confirmDeleteSelected', items);
    }

    const confirmDeleteProduct = (item: Donor) => {
      confirm.require({
        message: 'Действительно хотите удалить?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            item.id && (await api.remove(item.id));
            toast.add({
              severity: 'success',
              summary: 'Успешно',
              detail: 'Удалено',
              life: 3000,
            });
            await update();
          } catch (error) {
            if (error instanceof CustomError) {
              error.show('error', 5000, toast);
            } else {
              console.log(error);
            }
          }
        },
      });
    }

    const loadMore = async () => {
      if (page.value >= pages.value) {
        return;
      }

      loading.value = true;
      try {
        page.value += 1;
        const result = await api.fetchAll(page.value + 1, size.value);
        if (result.data) {
          products.value = [...products.value, ...result.data];
          loadedRecords.value += result.data.length;
        }
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    const contextMenu = [
      {
        label: 'Открыть',
        icon: 'pi pi-fw pi-search',
        command: () => {
          editProduct(selectedProduct.value);
        },
      },
      {
        label: 'Удалить',
        icon: 'pi pi-fw pi-times',
        command: () => {
          confirmDeleteProduct(selectedProduct.value);
        },
      },
    ];

    return {
      item,
      editMode,
      products,
      selectedProduct,
      selectedProducts,
      loading,
      totalRecords,
      loadedRecords,
      headerStyle,
      productDialog,
      contextMenu,
      cm,
      size,
      onRowContextMenu,
      updatePage,
      confirmDeleteSelected,
      openNew,
      getImageUrl,
      update,
      loadMore
    }
  }
});

<template>
  <div class="root">
    <Card>
      <template #content>
        <DonorsCatalog />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';

import DonorsCatalog from '@/components/Donors/DonorsCatalog.vue';

export default {
  components: {
    Card, DonorsCatalog,
  },
}
</script>

<style lang="scss" scoped>
.root {
  width: 100%;
  height: calc(100vh - 8rem);
}
</style>
